import { Component, OnInit } from '@angular/core';
import { DiService } from '@seech-sub/core-ng';

@Component({
  selector: 'sub-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  navItems: {label: string, link: string, prodHide?: boolean}[] = [
    {label: "home", link: "home"},
    {label: "profile", link: "profile"},
    {label: "security", link: "security"},
    {label: "settings", link: "settings", prodHide: true},
    {label: "payments", link: "payments", prodHide: true},
    {label: "ïntegrations", link: "ïntegrations", prodHide: true}
  ]; 
  isProduction!: boolean;

  constructor(private coreDi: DiService){}

  ngOnInit(): void {
    this.isProduction = this.coreDi.environment?.production;
  }

  showMenu(menu: {label: string, link: string, prodHide?: boolean}): boolean{
    return !(menu.prodHide && this.isProduction)
  }

}
