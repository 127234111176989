import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '@seech-sub/ux-ng';
import { REQUEST_META_DATA } from './interceptor.service';

@Injectable()
export class HttpProgressInterceptorInterceptor implements HttpInterceptor {
  skip_progress!: boolean;
  requestCount = 0;

  constructor(private loader: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.skip_progress = request.context.get(REQUEST_META_DATA.HIDE_PROGRESS);
    this.showLoader();

    return Observable.create((observer: any) => {
      const subscription = next.handle(request).subscribe(
        (event) => {
          if(event instanceof HttpResponse) observer.next(event);
        },
        (err) => {
          observer.error(err);
        },
        () => {
          this.hideLoader();
          observer.complete();
        });
        
        return () => {
        subscription.unsubscribe();
      };
    })
  }


  showLoader(){
    if(!this.skip_progress){
      this.requestCount++;
      this.loader.isLoading.next(true);
    }
  }
  hideLoader(){
    if(!this.skip_progress){
      this.requestCount--;
      if(this.requestCount === 0)
      this.loader.isLoading.next(false);
    }
  }
}
