import { ExtraOptions, Route } from '@angular/router';
import { LayoutComponent } from '@seech-sub/layout-ng';
import { canActivateGuard } from '@seech-sub/core-ng';
import { LoginComponent } from './components/login.component';

export const routerOptions: ExtraOptions = {
    initialNavigation: 'enabledBlocking',
};

export const appRoutes: Route[] = [
    {
        path: '', 
        component: LayoutComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'home',
                canActivate: [canActivateGuard],
                loadChildren: () => {
                    return import('./modules/features/home/home.module').then(m => m.HomeModule);
                }
            },
            {
                path: 'profile',
                canActivate: [canActivateGuard],
                loadChildren: () => {
                    return import('./modules/features/profile/profile.module').then(m => m.ProfileModule);
                }
            },
            {
                path: 'security',
                canActivate: [canActivateGuard],
                loadChildren: () => {
                    return import('./modules/features/security/security.module').then(m => m.SecurityModule);
                }
            },
            {
                path: 'settings',
                canActivate: [canActivateGuard],
                loadChildren: () => {
                    return import('./modules/features/settings/settings.module').then(m => m.SettingsModule);
                }
            },
            {
                path: 'ïntegrations',
                canActivate: [canActivateGuard],
                loadChildren: () => {
                    return import('./modules/features/integration/integration.module').then(m => m.IntegrationModule);
                }
            },
            {path: '', redirectTo: 'home', pathMatch: 'full'}
        ]
    },
];

