import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { CONSTANT } from '../core/constants';
import { Token } from '../models/model';
import { DiService } from './di.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private storageService: StorageService,
    private config: DiService
  ) {}

  // initialize(token: string) {
  //   this.storageService.initSession(token);
  // }

  isLoggedIn(): boolean {
    const token = this.getToken();
    return token?.access_token ? true : false;
  }

  public initSession(token: string) {
    this.storageService.set(CONSTANT.TOKEN_BEARER.TOKEN, token);
  }

  public getToken(): Token {
    return (
      this.storageService.getItem<Token>(CONSTANT.TOKEN_BEARER.TOKEN) || {}
    );
  }

  public updateSession(session: any) {
    this.storageService.set(CONSTANT.TOKEN_BEARER.TOKEN, session);
  }

  public reset(exclusions?: string[]) {
    if (exclusions && exclusions[0]) {
      const excluded = exclusions.map((exclusion) => ({
        key: exclusion,
        value: localStorage.getItem(exclusion),
      }));
      localStorage.clear();
      excluded.forEach((x) => localStorage.setItem(x.key, x.value as string));
    } else {
      localStorage.clear();
    }
  }

  gotoExternalLogin(returnUrl?: string) {
    this.storageService.reset();

    const origin =
      window.location.origin ||
      window.location.protocol + '//' + window.location.host;

    const url = `${this.config.environment.authWebURL}/login?redirect=${origin}/login&returnUrl=${returnUrl}`;
    window.location.href = this.config.environment.production
      ? url
      : `${url}&client_id=${this.config.environment.clientID}`;
  }

  logout() {
    this.gotoExternalLogin('/home');
  }
}
