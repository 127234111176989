<div class="layout">
    <sub-http-progress-indicator></sub-http-progress-indicator>

    <sub-mobile-nav #mobileNav></sub-mobile-nav>
    <sub-header id="header" (openMenu)="mobileNav.toggleSideNav()"></sub-header>

    <div class="layout-body">
        <sub-side-nav id="sidebar" [class.no-footer]="!showFooter"></sub-side-nav>
    
        <main id="main" [class.no-footer]="!showFooter">
            <router-outlet></router-outlet>
        </main>

        <sub-footer id="footer" *ngIf="showFooter"></sub-footer>
    </div>

</div>
