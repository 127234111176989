<ng-select 
    [items]="properties.options ?? []" 
    bindLabel="label" 
    bindValue="value" 
    [searchable]="properties.searchable === undefined ? false : properties.searchable" 
    [loading]="loading"
    [placeholder]="properties.label ?? ''"
    [clearable]="false"
    [multiple]="properties.isMultiSelect ?? false"
    [(ngModel)]="value"
    (change)="onValueChange()"
    [id]="properties.id"
    (blur)="markAsTouched()"
    (search)="search.emit($event)"
    (scrollToEnd)="loadMore.emit()">

    <ng-container *ngIf="properties.isMultiSelect">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.label}}
        </ng-template>
    </ng-container>
</ng-select>
<div class="error-msg">
    <ng-content></ng-content>
</div>
