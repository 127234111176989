import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DiService } from '@seech-sub/core-ng';

@Component({
  selector: 'sub-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Output() collapsed = new EventEmitter<boolean>();
  navItems: { label: string; link: string; prodHide?: boolean, icon?: any }[] = [
    { label: 'home', link: 'home', icon: 'home' },
    { label: 'profile', link: 'profile', icon: 'person' },
    { label: 'security', link: 'security', icon: 'secured' },
    { label: 'payments', link: 'payments', prodHide: true, icon: 'wallet' },
  ];

  navItems2: { label: string; link: string; prodHide?: boolean, icon?: any }[] = [
    { label: 'settings', link: 'settings', prodHide: true, icon: 'settings' },
    { label: 'ïntegrations', link: 'ïntegrations', prodHide: true, icon: 'work' }
  ];

  isProduction!: boolean;
  isCollapsed = false;  // Track the sidebar collapse state

  constructor(private coreDi: DiService) { }

  ngOnInit(): void {
    this.isProduction = this.coreDi.environment?.production;
  }

  showMenu(menu: { label: string; link: string; prodHide?: boolean }): boolean {
    return !(menu.prodHide && this.isProduction);
  }

  // Method to toggle the sidebar
  toggleSidebar(): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapsed.emit(this.isCollapsed);
  }
}
