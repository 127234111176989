import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANT, StorageService, UserInfo } from '@seech-sub/core-ng';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  userInfo!: UserInfo;

  constructor(private http: HttpClient,
              private storage: StorageService) { }

  getUserInfo(): void{
    this.http.get<UserInfo>('User').subscribe(
      (info: UserInfo) => {
        this.persistUserInfo(info); 
    });
  }

  private persistUserInfo(info: UserInfo){
    this.storage.set(CONSTANT.BASIC_INFO, info);
  }

}
