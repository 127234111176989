import { Inject, Injectable } from '@angular/core';
import { CORE_NG_TOKEN } from '../di.token';
import { CoreConfig, Environment } from '../models/core.config';

@Injectable({
  providedIn: 'root'
})
export class DiService {
  environment!: Environment;

  constructor(@Inject(CORE_NG_TOKEN) public config: CoreConfig) {
    this.environment = config.environment;
   }
}
