<nav class="sidebar">
    <ul>
        <ng-container *ngFor="let item of navItems">
            <li class="sidebar-item" *ngIf="showMenu(item)">
                <a [routerLink]="'/' + item.link" routerLinkActive="active" [id]="item.label + 'link'">
                    <img [src]="'assets/ux-ng/icons/' + item.link + '.svg'" [alt]="item.link + ' icon'" [id]="item.label + 'icon'">
                    <span class="ms-2"> {{item.label| titlecase}} </span> 
                </a>
            </li>
        </ng-container>
    </ul>
</nav>
