<div class="layout">
  <!-- <sub-http-progress-indicator></sub-http-progress-indicator> -->

  <sub-mobile-nav #mobileNav></sub-mobile-nav>
  <sub-header id="header" (openMenu)="mobileNav.toggleSideNav()"></sub-header>

  <div class="layout-body flex">
    <sub-side-nav
      [ngClass]="{
        'w-[17%]': !sidebarCollapsed,
        'w-auto': sidebarCollapsed
      }"
      (collapsed)="sidebarCollapsed = $event"
      id="sidebar"
      [class.no-footer]="!showFooter"
    ></sub-side-nav>

    <main class="w-full" id="main" [class.no-footer]="!showFooter">
      <div class="min-h-screen">
        <router-outlet></router-outlet>
      </div>

      <sub-footer></sub-footer>
    </main>

    <!-- <sub-footer id="footer" *ngIf="showFooter"></sub-footer> -->
  </div>
</div>
