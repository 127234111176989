import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBootstrapModule } from '../../general/mdbootstrap/mdbootstrap.module';
// import { ModalComponent, SelectComponent, SwitchComponent, TextboxComponent } from '@seech-sub/ux-ng';
// import { ImageUrlPipe } from '@seech-sub/core-ng';
import { MfaSetupComponent } from './mfa-setup/mfa-setup.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { VerifyPhoneComponent } from './verify-phone/verify-phone.component';
import { SecurityQuestionsAddEditComponent } from './security-questions-add-edit/security-questions-add-edit.component';
import { ProfilePreviewModalComponent } from './profile-preview-modal/profile-preview-modal.component';
import { EmailAddEditComponent } from './email-add-edit/email-add-edit.component';
import { PhoneAddEditComponent } from './phone-add-edit/phone-add-edit.component';
import { BasicInfoEditComponent } from './basic-info-edit/basic-info-edit.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AddressAddEditComponent } from './address-add-edit/address-add-edit.component';
import { SharedModule } from '../../shared/shared.module';

// const STANDALONE_COMPONENTS = [
//   ModalComponent,
//   TextboxComponent,
//   SelectComponent,
//   SwitchComponent,
//   ImageUrlPipe,
// ];

@NgModule({
  declarations: [
    AddressAddEditComponent,
    ChangePasswordComponent,
    BasicInfoEditComponent,
    PhoneAddEditComponent,
    EmailAddEditComponent,
    ProfilePreviewModalComponent,
    SecurityQuestionsAddEditComponent,
    VerifyPhoneComponent,
    VerifyEmailComponent,
    MfaSetupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MDBootstrapModule,
    SharedModule,
    // ...STANDALONE_COMPONENTS,
  ]
})
export class ModalsModule { }
