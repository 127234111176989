import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DiService } from '@seech-sub/core-ng';

@Component({
  selector: 'sub-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
})
export class MobileNavComponent implements OnInit {
  @ViewChild("sideNav") sideNav!: ElementRef;
  @ViewChild("content") contentArea!: ElementRef;
  isOpen = false;
  navItems: {label: string, link: string, prodHide?: boolean}[] = [
    {label: "home", link: "home"},
    {label: "profile", link: "profile"},
    {label: "security", link: "security"},
    {label: "settings", link: "settings", prodHide: true},
    {label: "payments", link: "payments", prodHide: true},
    {label: "ïntegrations", link: "ïntegrations", prodHide: true}
  ]; 
  isProduction!: boolean;

  constructor(private renderer: Renderer2, private coreDi: DiService){}

  ngOnInit(): void {
    this.isProduction = this.coreDi.environment?.production;
    this.renderer.listen('window', 'click', (e: Event) => {
      if(!this.contentArea.nativeElement.contains(e.target) && this.isOpen){
        this.toggleSideNav();
      }
    });
  }

  openSideNav(){
    this.renderer.addClass(this.sideNav.nativeElement, "open");
  }
  closeSideNav(){
    this.renderer.removeClass(this.sideNav.nativeElement, "open");
  }
  toggleSideNav(){
    this.isOpen ? this.closeSideNav() : this.openSideNav();
    const delay: number = this.isOpen ? 0 : 500;
    setTimeout(() => {
      this.isOpen = !this.isOpen;
    }, delay);
  }

  showMenu(menu: {label: string, link: string, prodHide?: boolean}): boolean{
    return !(menu.prodHide && this.isProduction)
  }
}

