import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchEvent, SeechInput } from '../../models';

@Component({
  selector: 'sub-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent)
    }
  ]
})
export class SelectComponent implements ControlValueAccessor {
  @Input() properties: SeechInput = {} as SeechInput;
  @Input() loading = false;
  @Output() loadMore = new EventEmitter<void>();
  @Output() search = new EventEmitter<SearchEvent>();
  value!: any;
  touched = false;
  disabled = false;

  onChange = (event: any) => {
    //intentionally not implemented; method added to initialize variable
  };
  onTouched = () => {
    //intentionally not implemented; method added to initialize variable
  };

  onValueChange(){
    this.onChange(this.value);
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.onValueChange();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
