import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreConfig } from './models/core.config';
import { CORE_NG_TOKEN } from './di.token';
import { DiService } from './services/di.service';

@NgModule({
  imports: [CommonModule],
  providers: [DiService]
})
export class CoreNgModule {
  public static forRoot(config: CoreConfig): ModuleWithProviders<CoreNgModule>{
    return {
      ngModule: CoreNgModule,
      providers: [{provide: CORE_NG_TOKEN, useValue: config}]
    };
  }
}
