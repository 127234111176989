import { Inject, Injectable, Optional } from '@angular/core';
import { LAYOUT_CONFIG_TOKEN } from './layout.token';
import { LayoutConfig } from '../models/layout.config';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(@Optional() @Inject(LAYOUT_CONFIG_TOKEN) public readonly config: LayoutConfig) { }
}
