<div
  class="img-placeholder bg-[#FFECE5] text-[#101928] border-2 border-primary"
  [ngStyle]="{
    width: size,
    height: size,
    'font-size': 'calc(' + size + ' / 2)'
  }"
>
  {{ getFullName | extractInitials }}
</div>
