import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractInitials',
  standalone: true
})
export class ExtractInitialsPipe implements PipeTransform {
  transform(value: string): string {
    if(!value){
      return '';
    }
    let initials = "";
    value.split(" ").forEach(x => {
      initials = x ? initials + x.charAt(0) : initials;
    });
    return initials;
  }
}
