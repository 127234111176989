<div
  class="toast toast-{{type === 'error' ? 'danger' : type}} mx-auto"
  id="basic-primary-example"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div class="toast-header toast-{{type === 'error' ? 'danger' : type}}">
    <strong class="me-auto" id="toastTitle"> {{ type | titlecase }} </strong>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="notificationRef.close()"
    ></button>
  </div>
  <div class="toast-body" id="toastBody"> {{ message }} </div>
</div>