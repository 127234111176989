<div class="modal-wrapper">
  <div class="modal-header">
    <h5 class="modal-title" id="modalTitle">{{ modalConfig.title }}</h5>

    <ng-container *ngIf="showClose">
      <button
        type="button"
        class="btn-close"
        id="closeBtn"
        aria-label="Close"
        (click)="modalRef.close()"
      ></button>
    </ng-container>
  </div>

  <div class="modal-body">
    <ng-content></ng-content>
  </div>

  <div
    class="modal-footer d-flex"
    [ngClass]="
      modalConfig.hasSwitch ? 'justify-content-between' : 'justify-content-end'
    "
  >
    <ng-container *ngIf="modalConfig?.hasSwitch">
      <sub-switch
        [(ngModel)]="isChecked"
        (ngModelChange)="checked.emit(isChecked)"
        [id]="modalSwitchId"
        [disabled]="disableSwitch"
      >
        &nbsp;&nbsp;{{ modalConfig.switchLabel }}
      </sub-switch>
    </ng-container>

    <div>
      <button
        class="btn btn-primary-outline me-2"
        aria-label="Close"
        [id]="secondaryBtnId"
        (click)="
          secondaryBtn === 'Cancel'
            ? modalRef.close()
            : secondaryBtnClicked.emit()
        "
      >
        {{ secondaryBtn }}
      </button>
      <button
        (click)="submitted.emit()"
        [id]="primaryBtnId"
        [disabled]="
          disabled ||
          primaryBtn === 'Saving' ||
          primaryBtn === 'Activating' ||
          primaryBtn === 'Deleting'
        "
        class="btn btn-{{
          primaryBtn === 'Delete' || primaryBtn === 'Deleting'
            ? 'danger'
            : 'primary'
        }}"
      >
        <ng-container
          *ngIf="
            primaryBtn === 'Saving' ||
            primaryBtn === 'Activating' ||
            primaryBtn === 'Deleting'
          "
        >
          <div
            class="spinner-border text-light me-1"
            role="status"
            id="progressSpinner"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        {{ primaryBtn }}
      </button>
    </div>
  </div>
</div>
