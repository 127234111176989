import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { SessionService } from '../services/session.service';
import { Observable } from 'rxjs';

export const canActivateGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService);
  const sessionService = inject(SessionService);
  const router = inject(Router);
  const token = sessionService.getToken();

  if (sessionService.isLoggedIn()) {
    return true;
  }
  
  router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  return false;

};

