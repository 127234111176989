import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbPopconfirmModule } from 'mdb-angular-ui-kit/popconfirm';
import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    MdbDatepickerModule,
    MdbAccordionModule,
    MdbModalModule,
    MdbTooltipModule,
    MdbPopconfirmModule,
    MdbNotificationModule,
  ],
})
export class MDBootstrapModule { }
