import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'sub-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SwitchComponent)
    }
  ]
})
export class SwitchComponent implements ControlValueAccessor {
  value!: boolean;
  @Input() whiteTrack = false;
  @Input() id!: string;
  onChange = (event: any) => {
    //intentionally not implemented; method added to initialize variable
  };
  onTouched = () => {
    //intentionally not implemented; method added to initialize variable
  };
  disabled = false;
  touched = false;

  writeValue(isChecked: boolean): void {
    this.value = isChecked;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  onToggle(){
    if(!this.touched){
      this.onTouched();
      this.touched = true;
    }
    this.onChange(this.value);
  }

}
