import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TextboxComponent,
  ModalComponent,
  SelectComponent,
  SwitchComponent,
} from '@seech-sub/ux-ng';
import { ImageUrlPipe } from '@seech-sub/core-ng';

const STANDALONE_COMPONENTS = [
  ModalComponent,
  TextboxComponent,
  SelectComponent,
  SwitchComponent,
  ImageUrlPipe
];

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    ...STANDALONE_COMPONENTS
  ],
  exports: [
    ...STANDALONE_COMPONENTS
  ]
})
export class SharedModule {}
