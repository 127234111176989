<ng-container *ngIf="properties?.type !== 'select' && properties?.type !== 'date'; else others">
    <mdb-form-control>
        <input 
            mdbValidate
            mdbInput 
            [type]="properties.type ?? 'text'" 
            [id]="properties.id" 
            [(ngModel)]="value"
            (ngModelChange)="onValueChange()"
            (blur)="markAsTouched()"
            [placeholder]="properties.label"
            class="form-control form-control-{{fieldSize}}"/>
    
        <label 
            mdbLabel 
            class="form-label" 
            [for]="properties.id" 
            style="font-size: 12px;"> 
            {{ properties.label }} 
        </label>

    </mdb-form-control> 
</ng-container>

<ng-template #others>

    <ng-container *ngIf="properties.type === 'date'; else select">
        <mdb-form-control>
            <input 
                mdbInput 
                [mdbDatepicker]="datepicker"
                type="text" 
                [id]="properties.id" 
                [(ngModel)]="value"
                (ngModelChange)="onValueChange()"
                (blur)="markAsTouched()"
                [placeholder]="properties.label"
                class="form-control form-control-lg"/>
        
            <label 
                mdbLabel 
                class="form-label" 
                [for]="properties.id" 
                style="font-size: 12px;"> 
                {{ properties.label }} 
            </label>
    
            <mdb-datepicker-toggle [mdbDatepicker]="datepicker" [id]="dateToggleId"></mdb-datepicker-toggle> 
            <mdb-datepicker  [id]="datePickerId" [inline]="true" [maxDate]="maxDate" [startDate]="maxDate" [format]="'mmm dd, yyyy'" #datepicker></mdb-datepicker>
        </mdb-form-control> 
    </ng-container>
 
    <ng-template #select>
        <mdb-form-control>
            <mdb-select 
                [filter]="properties.searchable === undefined ? false : properties.searchable" 
                (valueChange)="onValueChange()" 
                [(ngModel)]="value"
                [id]="properties.id" 
                [placeholder]="properties.label ?? ''" 
                (closed)="markAsTouched()">
              <mdb-option *ngFor="let option of properties.options; let i = index" [value]="option.value" [id]="'option-' + i">
                {{ option.label }}
              </mdb-option>
            </mdb-select>
    
            <label  
                mdbLabel 
                class="form-label"
                style="font-size: 12px;">
                {{ properties.label }}
            </label>
        </mdb-form-control>
    </ng-template>
    
</ng-template>

<div class="error-msg">
    <ng-content></ng-content>
</div>
