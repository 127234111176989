import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'sub-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  showFooter!: boolean;
  constructor(private layoutService: LayoutService){}

  ngOnInit(): void {
    this.showFooter = this.layoutService.config?.hasFooter ?? true;
  }
}
