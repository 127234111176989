import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { CONSTANT, SessionService, StorageService, UserInfo } from '@seech-sub/core-ng';

@Component({
  selector: 'sub-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() openMenu: EventEmitter<void> = new EventEmitter<void>();
  showSearch!: boolean;
  userInfo: UserInfo = {} as UserInfo;

  constructor(private layoutService: LayoutService,
              private storage: StorageService, 
              private sessionService: SessionService){}

  ngOnInit(): void {
    this.showSearch = this.layoutService.config?.hasSearch ?? false;
    this.storage.get<UserInfo>(CONSTANT.BASIC_INFO).subscribe((info: UserInfo) => {
      if(info)
      this.userInfo = info;
    });
  }

  logout(){
    this.sessionService.logout();
  }
}
