import { Injectable } from '@angular/core';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ToastComponent } from '../components/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private notificationService: MdbNotificationService) { }

  open(message: string, type: 'error' | 'success' | 'info' | 'warning'){
    const config: MdbNotificationConfig = {
      position: 'bottom-center',
      autohide: true,
      data: { message, type }
    }
    this.notificationService.open(ToastComponent, config);
  }
}
