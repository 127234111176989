import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';

@Component({
  selector: 'sub-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  message = 'Error Ocurred';
  type: 'error' | 'success' | 'info' | 'warning' = 'error';

  constructor(public notificationRef: MdbNotificationRef<ToastComponent>) {}
}
