<header class="header">
    <div class="header-content">
        <img src="assets/ux-ng/icons/menu.svg" alt="menu icon" role="button" class="header-content-menu" (click)="openMenu.emit()">

        <div class="logo-wrapper" class="logo">
            <a [routerLink]="['/home']" role="button" class="logo-link">
                <img src="assets/ux-ng/images/seech-logo.png" alt="seech logo" class="logo-link-img">
            </a>
        </div>
    
        <div class="form-group" *ngIf="showSearch" id="searchBar">                
            <input 
            type="text" 
            class="form-control has-search"
            placeholder="Search"
            autocomplete="off">
        </div>
    
        <ng-container *ngIf="userInfo.username; else usernamePlaceholder">
            <div class="user-name" id="username"><a routerLink="/profile"> {{ userInfo.username }} </a></div>
        </ng-container>
        <ng-template #usernamePlaceholder>
                <span class="user-name placeholder col-1" id="usernamePlaceholder"></span>
        </ng-template>
    
        <img src="assets/ux-ng//icons/vertical-divider.svg" alt="divider icon" class="divider">

        <div mdbDropdown id="navDropDown" [offset]="20" class="dropdown">
            <div id="dropdownMenuButton"
                class="user-photo"
                aria-expanded="false"
                mdbDropdownToggle
                role="button">
                <ng-container *ngIf="userInfo.id && (userInfo.profileImageURL | imageUrl | async) as picUrl; else imgLoader">
                    <ng-container *ngIf="picUrl !== 'showPlaceHolder'; else photoPlaceholder">
                        <img 
                            [src]="picUrl" 
                            alt="profile photo" 
                            id="profilePhoto"
                            class="user-photo-img">
                    </ng-container>

                    <ng-template #photoPlaceholder>
                        <sub-photo-placeholder 
                            [firstName]="userInfo.firstName"
                            [lastName]="userInfo.lastName"
                            id="photoPlaceholder"
                            size="33.75px">
                        </sub-photo-placeholder>
                    </ng-template>
                </ng-container>
        
                <ng-template #imgLoader>
                    <div class="d-flex justify-content-center align-items-center user-photo-img bg-light">
                        <div class="spinner-border spinner-border-sm text-info" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </ng-template>
            </div>

            <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><a class="dropdown-item" routerLink="/profile">Profile</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" (click)="logout()" role="button" id="logout">Log out</a></li>
            </ul>
        </div>

    </div>

</header>
