import { Component, Input, forwardRef, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { SeechInput } from '../../models';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'sub-textbox',
  standalone: true,
  imports: [CommonModule, MdbFormsModule, MdbSelectModule, MdbDatepickerModule, FormsModule, ReactiveFormsModule],
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextboxComponent)
    }
  ]
})

export class TextboxComponent implements ControlValueAccessor {
  @Input() dateToggleId!: string;
  @Input() datePickerId!: string;
  @Input() properties: SeechInput = {} as SeechInput;
  @Input() fieldSize: 'sm'  | 'lg' | 'default' = 'lg';
  value!: any;
  disabled = false;
  touched = false;

  onChange = (event: any) => {
    //intentionally not implemented; method added to initialize variable
  };

  onTouched = () => {
    //intentionally not implemented; method added to initialize variable
  };

  writeValue(obj:  any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValueChange(){
    this.onChange(this.value);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.onValueChange();
    }
  }

  get maxDate(): Date{
    const d = new Date();
    return new Date(d.getFullYear() - 13, d.getMonth(), d.getDate());
  }

}

