import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';


export interface DataItem<T> {
  key: string;
  value: T | null;
};

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private dataStore = new BehaviorSubject<DataItem<any> | null>(null);

  getItem<T>(key: string): T | null {
    const data = localStorage.getItem(key);
    try {
      return data ? JSON.parse(data) as T : null;
    } catch (error) {
      console.error('Failed to parse item from localStorage', error);
      return null;
    }
  }

  get<T>(key: string) {
    const existing = this.getItem(key);
    this.set(key, existing);

    return this.dataStore.pipe(
      filter(x => x?.key === key),
      map(y => y?.value as T)
    );
  }

  set<T>(key: string, value: T): void {
    if (!localStorage) {
      throw new Error('Local storage is not available');
    }

    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Failed to set item in localStorage', error);
      throw error;
    }

    this.dataStore.next({ key, value: this.getItem(key) });
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  reset(): void {
    localStorage.clear();
    this.initializeDataStore();
  }

  private initializeDataStore() {
    this.dataStore = new BehaviorSubject<DataItem<any> | null>(null);
  }
}
