import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ModalConfig } from '../../models/modal.model';
import { SwitchComponent } from '..';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'sub-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [CommonModule, SwitchComponent, FormsModule]
})
export class ModalComponent {
  @Input() modalConfig: ModalConfig = {} as ModalConfig;
  @Input() disabled = false;
  @Input() secondaryBtn: 'Change' | 'Cancel' | 'Back' = 'Cancel';
  @Input() primaryBtn: 'Delete' | 'Save' | 'Saving' | 'Continue' | 'Activate' | 'Activating' | 'Deleting' = 'Save';
  @Input() showClose = false;
  @Input() disableSwitch = false;
  @Input() primaryBtnId!: string;
  @Input() secondaryBtnId!: string;
  @Input() modalSwitchId!: string;
  @Output() checked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondaryBtnClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() isChecked = true;

  constructor(public modalRef: MdbModalRef<ModalComponent>) {}


}
