<div class="form-check form-switch">
  <input
    mdbCheckbox
    class="form-check-input"
    type="checkbox"
    [id]="id"
    [disabled]="disabled"
    [(ngModel)]="value"
    [class.bg-white-track]="whiteTrack"
    (ngModelChange)="onToggle()"
  />

  <label class="form-check-label" for="switch">
    <ng-content></ng-content>
  </label>
</div>
