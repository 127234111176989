export const CONSTANT = {
    TOKEN_BEARER: {
        TOKEN: 'token',
        BEARER: 'Bearer '
    },
    BASIC_INFO: 'basicInfo',
    DEFAULT_GUID: '00000000-0000-0000-0000-000000000000'
}

export const textValidatorLength = {
    minimumLength: 5,
    maximumLength: 15,
    verficationCodeLength: 6,
};

export const VERIFICATION_TYPE = {
    phone: 'phone',
    email: 'email',
};

export const ACTIVITY_NAME = {
    password: 'password-update',
};

export const ReactiveFormCustomValidator = {
    minimumNameLength: 2,
    minimumLength: textValidatorLength.minimumLength,
    maximumLength: textValidatorLength.maximumLength,
    verficationCodeLength: textValidatorLength.verficationCodeLength,
    passwordRegex: `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+=#$@$!%*?&":;<.,/|'>]).{${textValidatorLength.minimumLength},}$`,
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    nameCharacterRegex: '([a-zA-Z0-9-.]*)',
    userNameCharacterRegex: '([a-z0-9-._]*)',
    phoneRegex: '[0-9+]*'
};