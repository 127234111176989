<nav class="mobile-nav" #sideNav>
  <i
    class="fa fa-times fa-2x"
    (click)="toggleSideNav()"
    aria-hidden="true"
    id="toggle"
  ></i>

  <ul class="mobile-nav-list" #content>
    <p class="mobile-nav-list-logo">SEECH</p>

    <ng-container *ngFor="let item of navItems">
      <li class="mobile-nav-list-item" *ngIf="showMenu(item)" [id]="item.label">
        <a
          [routerLink]="'/' + item.link"
          routerLinkActive="active"
          class="mobile-nav-list-item-link"
          [id]="item.label + 'link'"
          (click)="toggleSideNav()"
        >
          <div class="content-wrapper">
            <img
              [src]="'assets/ux-ng/icons/' + item.link + '-white.svg'"
              [id]="item.label + 'icon'"
              [alt]="item.link + ' icon'"
            />
            <span> {{ item.label | titlecase }} </span>
          </div>
        </a>
      </li>
    </ng-container>
  </ul>
</nav>
