<nav class="sidebar shadow-sm" [ngClass]="{ collapsed: isCollapsed }">
  <div class="flex flex-col justify-between h-full">
    <div>
      <ul>
        <ng-container *ngFor="let item of navItems">
          <li class="sidebar-item !text-grey-200" *ngIf="showMenu(item)">
            <a
              [routerLink]="'/' + item.link"
              routerLinkActive="active"
              [id]="item.label + 'link'"
              class="flex items-center h-[47px]"
            >
              <i
                [sch-icon]="item.icon"
                class="icon !text-2xl !font-semibold"
              ></i>
              <span class="ms-2" *ngIf="!isCollapsed">
                {{ item.label | titlecase }}
              </span>
            </a>
          </li>
        </ng-container>

        <div class="border-t border-t-grey-100 mt-3 py-3">
          <ng-container *ngFor="let item of navItems2">
            <li class="sidebar-item !text-grey-200" *ngIf="showMenu(item)">
              <a
                [routerLink]="'/' + item.link"
                routerLinkActive="active"
                [id]="item.label + 'link'"
                class="flex items-center h-[47px]"
              >
                <i
                  [sch-icon]="item.icon"
                  class="icon !text-2xl !font-semibold"
                ></i>
                <span class="ms-2" *ngIf="!isCollapsed">
                  {{ item.label | titlecase }}
                </span>
              </a>
            </li>
          </ng-container>
        </div>
      </ul>
    </div>

    <!-- Toggle Button (back arrow) -->
    <div class="flex items-center justify-end py-3 pr-3">
      <i
        [sch-icon]="'prev-arrow'"
        class="text-primary border border-primary rounded-full h-7 w-7 text-lg flex items-center justify-center cursor-pointer"
        (click)="toggleSidebar()"
      ></i>
    </div>
  </div>
</nav>
